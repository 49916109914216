<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_config.commodity_market_price_list') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col>
              <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_division.division')"
                label-for="division_id"
                >
                <v-select name="division_id"
                    v-model="search.division_id"
                    label="text"
                    :options= divisionList
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
              </b-form-group>
            </b-col>
            <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('warehouse_config.region')"
          label-for="region_id"
          ><v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
            </b-col>
          </b-row>
        <b-row>
        <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_district.district')"
          label-for="district_id"
          >
          <v-select name="district_id"
                v-model="search.district_id"
                label="text"
                :options= regionDistrictLists
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
        </b-col>
        <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_upazilla.upazilla')"
          label-for="upazilla_id"
          >
          <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
      </b-col>
        </b-row>
    <b-row>
        <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_union.union')"
          label-for="union_id"
          >
          <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
      </b-col>
      <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          label-for="commodity_group_id"
          :label="$t('warehouse_information.warehouse_name')"
          >
          <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
      </b-col>
    </b-row>
    <b-row>
       <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('warehouse_config.crop_name')"
          label-for="commodity_group_id"
          >
          <v-select name="commodity_name_id"
                v-model="search.commodity_name_id"
                label="text"
                :options= commoditynameList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
          </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
            class="row"
            label-cols-sm="3"
            label-for="date"
        >
          <template v-slot:label>
            {{$t('warehouse_config.date')}}
          </template>
          <flat-pickr class="form-control"
                      v-model="search.price_date"
                      :placeholder="$t('globalTrans.select_date')"
          ></flat-pickr>
        </b-form-group>
      </b-col>
    </b-row>
        <b-row>
          <b-col class="col-12">
            <b-button type="button" variant="primary" @click="searchData" class="float-right">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_config.commodity_market_price_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(commodity_group_name)="data">
                      {{ data.item.commodity_group_name }}
                    </template>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                      <template v-slot:cell(division_name)="data">
                      {{ data.item.division_name }}
                    </template>
                     <template v-slot:cell(distict_name)="data">
                      {{ data.item.distict_name }}
                    </template>
                     <template v-slot:cell(upzila_name)="data">
                      {{ data.item.upzila_name }}
                    </template>
                    <template v-slot:cell(commodity_name)="data">
                      {{ data.item.commodity_name }}
                    </template>
                     <template v-slot:cell(price)="data">
                     {{ $n(data.item.price) }}
                    </template>
                    <template v-slot:cell(price_date)="data">
                      {{ data.item.price_date|dateFormat }}
                    </template>
                    <template v-slot:cell(status)="data">
                       <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                       <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                       <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Market Price Modify" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                          <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                          <i class="fas fa-toggle-off"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Status" size="sm" @click="remove(data.item)" v-else>
                          <i class="fa fa-toggle-on"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-if="pagination.totalRows"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>

import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseMarketPriceList, warehouseMarketPriceToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        division_id: null,
        district_id: null,
        region_id: null,
        upazilla_id: null,
        union_id: null,
        commodity_name_id: null,
        warehouse_id: null,
        price_date: ''
      },
      districtList: [],
      unionList: [],
      upazilaList: [],
      regionDistrictLists: [],
      warehouseList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.editItemId === 0) ? this.$t('warehouse_config.commodity_market_price_entry') : this.$t('warehouse_config.commodity_market_price_update')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_config.commodity_warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_config.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_config.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_config.market'), class: 'text-left' },
          { label: this.$t('warehouse_config.commodity_current_price'), class: 'text-left' },
          { label: this.$t('warehouse_config.date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'market_name_bn' },
          { key: 'price' },
          { key: 'price_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_name' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'market_name' },
          { key: 'price' },
          { key: 'price_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionList: function () {
      let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
      if (this.isWareHouseUser) {
        regionList = regionList.filter(item => item.value === this.search.region_id)
      }
      return regionList
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    commoditynameList: function () {
      return this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    }
  },
   watch: {
    // 'search.division_id.value': function (newVal, oldVal) {
    //   this.regionDistrictLists = this.getDistrictList(newVal)
    // },
    'search.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    }
  },
  async created () {
  },
  mounted () {
    if (this.isWareHouseUser) {
      this.search.division_id = this.authUser.office_detail.division_id
      this.search.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.search.district_id = this.authUser.office_detail.district_id
      this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.search.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.search.warehouse_id = warehouse.value
    }
    this.loadData()
  },
  methods: {
    async searchData () {
      await this.loadData()
    },
    remove (item) {
      this.changeStatus(warehouseServiceBaseUrl, warehouseMarketPriceToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(warehouseServiceBaseUrl, warehouseMarketPriceList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.ListDataShow(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    },
    ListDataShow (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      let tmpWarehouseData = {}
      let tmpCropType = {}
      let tmpCropName = {}
      let tmpMarketInfo = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id && division.status === 0)
        tmpData2 = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id && district.status === 0)
        tmpUpzila = this.$store.state.commonObj.upazilaList.find(upizila => upizila.value === item.upazilla_id && upizila.status === 0)
        tmpWarehouseData = this.$store.state.warehouse.warehouseInfoList.find(wareItem => wareItem.value === item.warehouse_id && wareItem.status === 0)
        tmpCropType = this.$store.state.warehouse.commodityGroupList.find(typeID => typeID.value === item.commodity_group_id && typeID.status === 0)
        tmpCropName = this.$store.state.warehouse.commodityNameList.find(CropName => CropName.value === item.commodity_name_id && CropName.status === 0)
        tmpMarketInfo = this.$store.state.warehouse.marketInfoList.find(marketInfo => marketInfo.value === item.market_info_id && marketInfo.status === 0)
        const orgData = {
            division_name: tmpData !== undefined ? tmpData.text_en : '',
            division_name_bn: tmpData !== undefined ? tmpData.text_bn : '',
            distict_name: tmpData2 !== undefined ? tmpData2.text_en : '',
            distict_name_bn: tmpData2 !== undefined ? tmpData2.text_bn : '',
            upzila_name: tmpUpzila !== undefined ? tmpUpzila.text_en : '',
            upzila_name_bn: tmpUpzila !== undefined ? tmpUpzila.text_bn : '',
            warehouse_name: tmpWarehouseData !== undefined ? tmpWarehouseData.text : '',
            warehouse_name_bn: tmpWarehouseData !== undefined ? tmpWarehouseData.text_bn : '',
            commodity_group_name: tmpCropType !== undefined ? tmpCropType.text : '',
            commodity_group_name_bn: tmpCropType !== undefined ? tmpCropType.text_bn : '',
            commodity_name: tmpCropName !== undefined ? tmpCropName.text : '',
            commodity_name_bn: tmpCropName !== undefined ? tmpCropName.text_bn : '',
            market_name: tmpMarketInfo !== undefined ? tmpMarketInfo.text : '',
            market_name_bn: tmpMarketInfo !== undefined ? tmpMarketInfo.text_bn : ''
        }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
