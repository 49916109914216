<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <ValidationProvider name="Division" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="document.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" rules="required|min_value:1">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_config.region') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="document.region_id"
                        :options="regionList"
                        id="region_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="document.district_id"
                      :options="districtList"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="document.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Union" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('globalTrans.union') }} <span class="text-danger"></span>
                    </template>
                    <b-form-select
                      plain
                      v-model="document.union_id"
                      :options="unionList"
                      id="union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Type" vid='warehouse_type_id' rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="document.warehouse_type_id"
                        :options="warehouseTypeList"
                        id="warehouse_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Category" vid='warehouse_category_id' rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_category_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse_category')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="document.warehouse_category_id"
                        :options="warehouseCategoryList"
                        id="warehouse_category_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_id"
                      :label="$t('warehouse_information.warehouse_name')"
                       slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                          {{$t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
                         </template>
                      <b-form-select
                        plain
                        v-model="document.warehouse_id"
                        :options="warehouseList"
                        id="warehouse_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                         {{ errors[0] }}
                       </div>
                      </b-form-group>
                 </ValidationProvider>
                 <ValidationProvider name="Crop Type" vid="commodity_group_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="commodity_group_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_config.crop_type')}} <span class="text-danger">*</span>
                         </template>
                          <b-form-select
                          plain
                          v-model="document.commodity_group_id"
                          :options="cropyTypeList"
                          id="commodity_group_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                 </ValidationProvider>

                 <ValidationProvider name="Crop Name" vid="group_name" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="group_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('warehouse_config.crop_name')}} <span class="text-danger">*</span>
                         </template>
                          <b-form-select
                          plain
                          v-model="document.commodity_name_id"
                          :options="commoditynameList"
                          id="commodity_name_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>

                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                  </ValidationProvider>

                    <ValidationProvider name="Date" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('warehouse_config.date')}} <span class="text-danger">*</span>
                      </template>
                        <flat-pickr class="form-control"
                          v-model="document.price_date"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></flat-pickr>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div v-if="!id">
                      <b-row v-for="(price_data_item, index) in document.price_data" :key="index">
                        <b-col lg="11" sm="11" class="pr-lg-0 pr-sm-0">
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Market Info" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="market_info_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('warehouse_config.market') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="price_data_item.market_info_id"
                                      :options="marketInfoList"
                                      id="market_info_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Current Price" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="price"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('warehouse_config.commodity_current_price')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="price"
                                      v-model="price_data_item.price"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="1" sm="1">
                          <b-button v-show="index == document.price_data.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                          <b-button v-show="index || ( !index && document.price_data.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-else>
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Market Info" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="market_info_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('warehouse_config.market') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="document.market_info_id"
                                  :options="marketInfoList"
                                  id="market_info_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Current Price" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="price"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.commodity_current_price')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="price"
                                  v-model="document.price"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseMarketPriceStore, warehouseMarketPriceUpdate } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getMarketPriceData()
      this.document = tmp
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
        this.document.division_id = this.authUser.office_detail.division_id
        this.document.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.document.district_id = this.authUser.office_detail.district_id
        this.document.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.document.union_id = this.authUser.office_detail.union_id ?? 0
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.document.warehouse_id = warehouse.value
        this.document.warehouse_type_id = warehouse.warehouse_type_id
        this.warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(doc => doc.value === warehouse.warehouse_type_id)
        this.document.warehouse_category_id = warehouse.warehouse_category_id
        this.warehouseCategoryList = this.$store.state.warehouse.warehouseCategoryList.filter(doc => doc.value === warehouse.warehouse_category_id)
    }
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      document: {
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        region_id: '0',
        union_id: '0',
        price_date: '',
        price: '',
        commodity_group_id: 0,
        commodity_name_id: 0,
        warehouse_type_id: 0,
        warehouse_category_id: 0,
        warehouse_id: 0,
        price_data: [
          {
            market_info_id: 0,
            price: ''
          }
        ]
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      commoditynameList: [],
      warehouseList: this.$store.state.warehouse.warehouseInfoList,
      marketInfoList: [],
      warehouseCategoryList: this.$store.state.warehouse.warehouseCategoryList.filter(item => item.status === 0),
      warehouseTypeList: this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    }
  },
  computed: {
        ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    cropyTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    regionList: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    }
  },
  watch: {
    'document.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'document.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    },
    'document.upazilla_id': function (newVal, oldVal) {
        this.warehouseList = this.getWarehouse(newVal, 'upz')
        this.unionList = this.getUnionList(newVal)
    },
    'document.union_id': function (newVal, oldVal) {
        this.warehouseList = this.getWarehouse(newVal, 'up')
    },
    'document.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseCategoryList = this.getWarehouseCategoryList(newVal)
    },
    'document.warehouse_category_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouse(newVal, 'ct')
    },
    'document.warehouse_id': function (newVal, oldVal) {
      this.marketInfoList = this.getMarketInfoList(newVal)
    },
    'document.commodity_group_id': function (newVal, oldVal) {
      this.commoditynameList = this.getCommoNamelist(newVal)
    }
  },
  methods: {
    add () {
      const priceDataItem = {
        market_info_id: 0,
        price: ''
      }
      this.document.price_data.push(priceDataItem)
    },
    remove (key) {
      this.document.price_data.splice(key, 1)
    },
    getMarketPriceData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      let result = null
      const loadinState = { loading: false, listReload: true }

      if (this.document.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${warehouseMarketPriceUpdate}/${this.id}`, this.document)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseMarketPriceStore, this.document)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
    },
    getCommoNamelist (cropId) {
      const cropList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
      if (cropId) {
        return cropList.filter(document => document.commodity_group_id === cropId)
      }
      return cropList
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseTypeList (unionId = null) {
      const warehouseInfoList = [...new Set(this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0 && item.union_id === unionId))]
      const warehouseTypeIdArr = []

      if (warehouseInfoList.length) {
        warehouseInfoList.forEach(item => {
          warehouseTypeIdArr.push(item.warehouse_type_id)
        })
      }
      const warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0 && warehouseTypeIdArr.includes(item.value))

      return warehouseTypeList
    },
    getWarehouseCategoryList (warehouseTypeId = null) {
      const warehouseInfoList = [...new Set(this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0 && item.warehouse_type_id === warehouseTypeId))]
      const warehouseCategoryIdArr = []

      if (warehouseInfoList.length) {
        warehouseInfoList.forEach(item => {
          warehouseCategoryIdArr.push(item.warehouse_category_id)
        })
      }
      const warehouseCategoryList = this.$store.state.warehouse.warehouseCategoryList.filter(item => item.status === 0 && warehouseCategoryIdArr.includes(item.value))

      return warehouseCategoryList
    },
    getWarehouse (warehouseCategoryId = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (warehouseCategoryId && type === 'dis') {
        return warehouseList.filter(warehouse => warehouse.district_id === warehouseCategoryId)
      }

      if (warehouseCategoryId && type === 'upz') {
        return warehouseList.filter(warehouse => warehouse.upazilla_id === warehouseCategoryId)
      }

      if (warehouseCategoryId && type === 'up') {
        return warehouseList.filter(warehouse => warehouse.union_id === warehouseCategoryId)
      }

      if (warehouseCategoryId && type === 'ct') {
        return warehouseList.filter(warehouse => warehouse.warehouse_category_id === warehouseCategoryId)
      }

      return warehouseList
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
            const dataObject = response.filter(document => document.region_id === regionId)
            this.districtList = dataObject.map(obj => {
              const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
              if (typeof district !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.district_id, text: district.text_bn }
                } else {
                    return { value: obj.district_id, text: district.text_en }
                }
              }
            })
        }
      })
    },
    getMarketInfoList (warehouseId = null) {
      const marketInfoList = this.$store.state.warehouse.marketInfoList.filter(item => item.status === 0)

      if (warehouseId) {
        return marketInfoList.filter(marketInfo => marketInfo.warehouse_id === warehouseId)
      }

      return marketInfoList
    }
  }
}

</script>
